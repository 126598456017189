import React, { useContext, useState } from 'react';
import Button from '../Global/Button';
import { useForm } from 'react-hook-form';

//@ts-ignore
import * as styles from './what-sauna.module.css';
import RichText from '../Global/RichText';
import { WhatSaunaProps } from './index';
import WhatSaunaResultsContainer from '../WhatSaunaResults/index';
import { ProductPagePreview } from '../Global/DataUtils/productQueries';
import GatsbyImageWithIEPolyfill from 'gatsby-image/withIEPolyfill';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import Jotform from '../Jotform';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
const motion = {
    div: createDomMotionComponent('div'),
};

interface WellnessType {
    codename: string;
    name: string;
    img: string;
}
interface FormProps extends WhatSaunaProps {
    allProducts: ProductPagePreview[];
    wellness_type_for_german_site: {
        value: WellnessType[];
    };
    wellness_type_for_french_site: {
        value: WellnessType[];
    };
}

const WhatSaunaForm: React.FC<FormProps> = ({
    allProducts,
    headline,
    description_copy,
    interested_in_subheadline,
    anchor_name,
    contact_form,
    capacity_need_subheadline,
    wellness_type,
    capacity_tag,
    capacity_tag_for_german_site,
    capacity_tag_for_french_site,
    results_headline,
    cta_label,
    wellness_type_for_german_site,
    wellness_type_for_french_site,
    ...props
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [emailFormParams, setEmailFormParams] = useState('');
    const [selectedWellnessTags, setSelectedWellnessTags] = useState([]);
    const [selectedCapacity, setSelectedCapacity] = useState('');
    const [showResults, setShowResults] = useState(false);
    const pageMetadata = useContext(PageMetadataContext);
    const capacityTag =
        pageMetadata.preferredLanguage == 'DE-de'
            ? capacity_tag_for_german_site
            : pageMetadata.preferredLanguage == 'DE-de'
            ? capacity_tag_for_french_site
            : capacity_tag;

    const setWellnessTypeForGerman = () => {
        return wellness_type?.value?.map((type, index) => {
            type.name = wellness_type_for_german_site?.value[index]?.name;
            if (wellness_type_for_german_site?.value[index]?.img) {
                type.img = wellness_type_for_german_site?.value[index]?.img;
            }
            return type;
        });
    };

    const setWellnessTypeForFrance = () => {
        return wellness_type?.value?.map((type, index) => {
            type.name = wellness_type_for_french_site?.value[index]?.name;
            if (wellness_type_for_french_site?.value[index]?.img) {
                type.img = wellness_type_for_french_site?.value[index]?.img;
            }
            return type;
        });
    };

    const wellnessType =
        pageMetadata.preferredLanguage == 'DE-de'
            ? setWellnessTypeForGerman()
            : pageMetadata.preferredLanguage == 'FR-fr'
            ? setWellnessTypeForFrance()
            : wellness_type.value;

    const onSubmit = (data: any) => {
        const wellness = Array.isArray(data.wellnessTags)
            ? data?.wellnessTags?.join(',')
            : data.wellnessTags;

        setSelectedWellnessTags(data?.wellnessTags);
        setSelectedCapacity(data?.capacity);

        if (contact_form?.value) {
            setEmailFormParams(
                `&capacity=${data?.capacity}&wellness=${wellness}`
            );
            setShowEmailForm(true);
        } else {
            setShowResults(true);
        }
    };

    const handleEmailFormDone = () => {
        setShowResults(true);
    };

    const handleEmailFormReady = () => {
        // form ready
    };

    return (
        <section className={styles.section} id={anchor_name?.value}>
            <AnimatePresence>
                {!showResults && (
                    <motion.div
                        key="question"
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={styles.container}
                    >
                        <div className={styles.contentWrapper}>
                            <div className={styles.content}>
                                <h2>{headline?.value}</h2>
                                {RichText(description_copy)}
                            </div>
                        </div>
                        <div className={styles.quiz}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label className={styles.largeLabel}>
                                        {interested_in_subheadline?.value}
                                    </label>
                                    <div className={styles.selections}>
                                        {wellnessType?.map((tag) => (
                                            <div
                                                key={tag.codename}
                                                className={styles.selection}
                                            >
                                                <input
                                                    type="checkbox"
                                                    placeholder="Please select an option."
                                                    id={tag.codename}
                                                    value={tag.codename}
                                                    className="sr-only"
                                                    {...register(
                                                        'wellnessTags',
                                                        { required: true }
                                                    )}
                                                />
                                                <label
                                                    className={
                                                        styles.imageLabel
                                                    }
                                                    htmlFor={tag.codename}
                                                >
                                                    <GatsbyImageWithIEPolyfill
                                                        fluid={tag.img}
                                                        alt={tag.name}
                                                    />
                                                </label>
                                                <label
                                                    className={
                                                        styles.smallLabel
                                                    }
                                                    htmlFor={tag.codename}
                                                >
                                                    {tag.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    {errors?.wellnessTags?.message && (
                                        <p>{errors.wellnessTags.message}</p>
                                    )}
                                </div>
                                <div className={styles.roomSelection}>
                                    <label className={styles.largeLabel}>
                                        {capacity_need_subheadline?.value + ' '}
                                    </label>
                                    <div className="select-wrapper select-wrapper--alt-orange">
                                        <select
                                            {...register('capacity', {
                                                required: true,
                                            })}
                                        >
                                            {capacityTag?.value?.map((tag) => (
                                                <option
                                                    key={tag.codename}
                                                    value={tag.codename}
                                                >
                                                    {tag.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <Button
                                        type="submit"
                                        visualStyle="outlineDark"
                                    >
                                        {cta_label?.value
                                            ? cta_label?.value
                                            : 'FIND MY SAUNA'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                        {showEmailForm && contact_form?.value && (
                            <Jotform
                                formUrl={contact_form?.value}
                                formStyle="popup"
                                params={emailFormParams}
                                onPopUpClose={handleEmailFormDone}
                                display={true}
                            />
                        )}
                    </motion.div>
                )}
                {showResults && (
                    <motion.div
                        key="answer"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 1 }}
                    >
                        <WhatSaunaResultsContainer
                            allProducts={allProducts}
                            results_headline={results_headline}
                            selectedWellnessTags={selectedWellnessTags}
                            selectedCapacity={selectedCapacity}
                            {...props}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </section>
    );
};

export default WhatSaunaForm;
